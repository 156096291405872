import { Config, User } from '@/types';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { callWhoAmIWithRetry } from './functions/utils';
import { ServiceDisruption } from "./modules/core/app/service-disrupted";
import { LazyApplication } from "./modules/core/app/lazy-application";
import { createInitServices } from "./modules/core/init-services";

import './index.css';

function renderApplication(user: User, config: Config) {
  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <LazyApplication user={user} config={config} />
  )
}

async function startApp() {
  const config: Config = await getConfig();
  const services = createInitServices({ backendUrl: '/' }, config);
  const user = await callWhoAmIWithRetry(services, 0, 3, 1000);
  if (!(user instanceof Error)) {
    return renderApplication(user, config);
  } else {
    if (user.message.includes('whoami retry exceeded')) {
      return ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
        <ServiceDisruption />
      );
    }
  }
}

async function getConfig() {
  return await fetch('/config', {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }).then(res => res.json());
}

(async () => {
  if (import.meta.env.VITE_APP_MOCKED === 'true') {
    console.log('Loading MSW...');
    void import('./mocks/browser')
      .then(({ worker }) => worker.start())
      .then(startApp);
  } else {
    void startApp();
  }
})();
