import type { Config } from "@/types";
import { AskBlueJApi, IAskBlueJApi } from "./api/ask-bluej-api";

type ServicesConfig = {
  backendUrl: string;
}

export type InitServices = {
  askBlueJApi: IAskBlueJApi;
}

export function createInitServices(servicesConfig: ServicesConfig, config: Config): InitServices {
  const askBlueJApi = new AskBlueJApi(servicesConfig.backendUrl, `${config.apiUrl}/`);
  return {
    askBlueJApi
  }
}
