import type { User } from '@/types';

import { InitServices } from "../modules/core/init-services";

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function callWhoAmIWithRetry(services: InitServices, attempt: number, maxAttempts: number, delay: number): Promise<User | Error> {
  try {
    return await services.askBlueJApi.whoami();
  } catch (error) {
    if (error instanceof Error && error.message.includes('401')) {
      return error;
    }
    if (attempt >= maxAttempts) {
      return Error('whoami retry exceeded');
    }
    await sleep(2 ** attempt * delay);
    return callWhoAmIWithRetry(services, attempt + 1, maxAttempts, delay);
  }
}
