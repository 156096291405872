import { ErrorPage } from "../errors/error-page";
import React from "react";

export function ServiceDisruption() {
  return (
    <ErrorPage
      title="Temporary Service Disruption"
      description={(
        <div>
          <p>We are currently experiencing a service disruption to Blue J. For direct updates as new information becomes
            available, please visit our <a className="text-blue-400" href="https://status.askbluej.com/" target="_blank"
                                           rel="noreferrer noopener">status page</a>.</p>
        </div>
      )}
    />
  )
}
